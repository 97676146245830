<template>
  <page-content>
    <v-toolbar flat>
      <app-tabs v-model="selectedTab">
        <v-tab v-for="tab of tabs" :key="tab.id" :to="'#' + tab.id">
          {{ tab.name }}
        </v-tab>
      </app-tabs>
      <v-spacer></v-spacer>
      <create-btn icon="mdi-note-plus" text="Utwórz" tooltip="Nowa Notatka" @click="onNewNote"></create-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <app-toolbar>
      <v-col cols="12" md="6">
        <app-filter-field label="Kryptonim" v-model="filter" @input="onFilter"></app-filter-field>
      </v-col>
      <v-col cols="6" md="6"> </v-col>
    </app-toolbar>

    <v-divider></v-divider>

    <v-card-subtitle>
      <v-row dense align="center" justify="center">
        <v-col cols="3">Obiekt</v-col>
        <v-col cols="7" class="pl-3">Notatka</v-col>
        <v-col cols="2">Daty</v-col>
      </v-row>
    </v-card-subtitle>

    <span class="">
      <v-row dense align="center" v-for="note of rowData" :key="note.id">
        <v-col cols="12" class="">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="3" class="">
          <site-link :site="note.site"></site-link>
        </v-col>
        <v-col cols="9" class="pl-3">
          <note :note="note" class="my-2" editable deletable @deleted="deleted"></note>
        </v-col>
      </v-row>
    </span>
    <v-card-actions class="justify-center">
      <!-- <v-pagination
        v-if="pages > 1"
        v-model="page"
        :length="pages"
        circle
      ></v-pagination> -->
    </v-card-actions>
  </page-content>
</template>

<script>
import SiteService from "@/services/site.service"

export default {
  name: "App",
  data() {
    return {
      filter: "",
      defaultColDef: null,
      columnDefs: null,
      rowData: null,
      tabs: SiteService.getNotesRanges(),
      selectedTab: null
    }
  },
  watch: {
    selectedTab() {
      this.refresh()
    },
  },
  components: {},
  methods: {
    onFilter() {
      // this.gridApi.setQuickFilter(this.filter)
      this.refresh()
    },
    deleted(note) {
      this.rowData.splice(
        this.rowData.findIndex(n => n.id == note.id),
        1
      )
    },
    refresh() {
      var filter = this.filter
      var conditions = this.tabs.find(t => t.id == this.selectedTab)?.conditions ?? []
      console.log("Filters are", filter)
      if (filter?.startsWith("#")) {
        conditions.push(`code_name__startswith=${filter.replace("#", "")}`)
        filter = ""
      }
      // const ordering = this.sortBySelected.ordering
      const query = {
        search: filter,
        page: 1,
        conditions: conditions
      }
      SiteService.fetchNotes(query).then(notes => {
        this.rowData = notes
      })
    },
    onNewNote() {
      this.$router.push({
        name: "New Note",
      })
    },
  },
  beforeMount() {
    this.selectedTab = this.tabs.find(c => c.id == this.$route.hash.replace(/^#/, ""))?.id ?? this.tabs[0].id
    this.refresh()
  }
}
</script>
